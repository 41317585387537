import { fetchHouseEntryInformationDetails } from 'api/agents/house';
import {
  DONE_VISITS_CARD_OPENED_ANALYTICS,
  DONE_VISITS_FILTER_CLICKED_ANALYTICS,
  DONE_VISITS_PAGE_VIEWED_ANALYTICS,
  DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS,
  DONE_VISITS_TAB_CLICKED_ANALYTICS,
  FETCH_HOUSE_AUTONOMOUS_AGENT,
  SET_VISIBLE_DONE_VISITS_DIALOG,
  SHOULD_FETCH_VISITS,
  VISIT_HOUSE_FETCH_SUCCESS,
  VISIT_USER_FETCH_SUCCESS,
  DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS,
  DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS,
  DONE_VISITS_SEND_OFFER_ANALYTICS,
  DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
  DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS,
} from './constants';

export const fetchHouseAutonomousAgent = ({
  agentId,
  houseId,
  businessContext,
}) => {
  const fields = ['type', 'ciq', 'listings'];
  return {
    type: FETCH_HOUSE_AUTONOMOUS_AGENT,
    promise: fetchHouseEntryInformationDetails({ agentId, houseId, fields }),
    meta: {
      houseId,
      businessContext,
    },
  };
};

export const setVisibleDoneVisitsDialog = (payload) => ({
  type: SET_VISIBLE_DONE_VISITS_DIALOG,
  payload,
});

export const shouldFetchVisits = (status) => ({
  type: SHOULD_FETCH_VISITS,
  payload: {
    status,
  },
});

export const visitHouseFetchSuccess = (payload) => ({
  type: VISIT_HOUSE_FETCH_SUCCESS,
  payload,
});

export const visitUserFetchSuccess = (payload) => ({
  type: VISIT_USER_FETCH_SUCCESS,
  payload,
});

export const triggerDoneVisitsPageViewed = (origin) => ({
  type: DONE_VISITS_PAGE_VIEWED_ANALYTICS,
  extraProperties: {
    origin,
  },
});

export const triggerDoneVisitsFilterClicked = () => ({
  type: DONE_VISITS_FILTER_CLICKED_ANALYTICS,
});

export const triggerDoneVisitsCardOpened = () => ({
  type: DONE_VISITS_CARD_OPENED_ANALYTICS,
});

export const doneVisitsPhoneClickedAnalytics = ({
  agentId,
  visitorId,
  channel,
}) => ({
  type: DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS,
  extraProperties: {
    agent_id: agentId,
    visitor_id: visitorId,
    channel,
  },
});

export const doneVisitsChatWithTenantClickedAnalytics = ({
  agentId,
  visitorId,
  channel,
}) => ({
  type: DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS,
  extraProperties: {
    agent_id: agentId,
    visitor_id: visitorId,
    channel,
  },
});

export const doneVisitsSendOfferClickedAnalytics = ({
  agentId,
  visitorId,
}) => ({
  type: DONE_VISITS_SEND_OFFER_ANALYTICS,
  extraProperties: {
    agent_id: agentId,
    visitor_id: visitorId,
  },
});

export const doneVisitsChatWithOwnerClicked = () => ({
  type: DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
});

export const doneVisitsFinishVisitClicked = () => ({
  type: DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS,
});

export const doneVisitsPendingTabClicked = () => ({
  type: DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS,
});

export const doneVisitsTabClicked = () => ({
  type: DONE_VISITS_TAB_CLICKED_ANALYTICS,
});
