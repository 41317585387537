export const DONE_VISITS_CARD_OPENED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_CARD_OPENED_ANALYTICS';
export const DONE_VISITS_FILTER_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_FILTER_CLICKED_ANALYTICS';
export const DONE_VISITS_PAGE_VIEWED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_PAGE_VIEWED_ANALYTICS';
export const FETCH_DONE_VISITS = 'agentsPwa/DoneVisits/FETCH_DONE_VISITS';
export const FETCH_HOUSE_AUTONOMOUS_AGENT =
  'agentsPwa/DoneVisits/FETCH_HOUSE_AUTONOMOUS_AGENT';
export const SHOULD_FETCH_VISITS = 'agentsPwa/DoneVisits/SHOULD_FETCH_VISITS';
export const SET_VISIBLE_DONE_VISITS_DIALOG =
  'agentsPwa/DoneVisits/SET_VISIBLE_DONE_VISITS_DIALOG';
export const VISIT_HOUSE_FETCH_SUCCESS =
  'agentsPwa/DoneVisits/VISIT_HOUSE_FETCH_SUCCESS';
export const VISIT_USER_FETCH_SUCCESS =
  'agentsPwa/DoneVisits/VISIT_USER_FETCH_SUCCESS';
export const DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS';
export const DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS';
export const DONE_VISITS_SEND_OFFER_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_CHAT_SEND_OFFER_ANALYTICS';
export const DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS';
export const DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS';
export const DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS';
export const DONE_VISITS_TAB_CLICKED_ANALYTICS =
  'agentsPwa/DoneVisits/DONE_VISITS_TAB_CLICKED_ANALYTICS';

export const DONE_VISITS = 'doneVisits';
export const PENDING_VISITS = 'pendingVisits';

export const DONE_VISITS_PAGE_TABS = Object.freeze({
  PENDING: 0,
  DONE: 1,
});

export const VISITS_DONE_AREA = 'VISITS_DONE_AREA';
export const COMPLETED_VISITS_TAB_BUTTON = 'completed_visits_tab_button';
export const TRACK_REVIEW_LISTING_INFO_FORM_SUBMITTED =
  'review_listing_info_form_submitted';

export const DONE_VISITS_TRACKING_EVENTS_ORIGIN = Object.freeze({
  MENU: 'menu',
});
