import { fetchHouseEntryInformationDetails } from 'api/agents/house';
import {
  RENT_PROGRESS_STATUS_PAGE_VIEWED,
  RENT_PROGRESS_STATUS_CARD_OPENED,
  RENT_PROGRESS_STATUS_DOCUMENTATION,
} from './../constants';

import {
  FETCH_DOCUMENTATIONS_REQUEST,
  SHOULD_FETCH_DOCUMENTATIONS,
  DOCUMENTATION_HOUSE_FETCH_SUCCESS,
  DOCUMENTATION_USER_FETCH_SUCCESS,
  CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION,
  FETCH_RENT_FLOW_DOCUMENTATIONS,
} from './constants';
import { fetchRentFlowsRequest } from '../Data/fetchRentFlowsRequest';

export const fetchDocumentationsRequest = ({
  userId,
  agentId,
  filters,
  lastItem,
  businessContext,
  retryCount,
  currentPage,
  pageSize,
}) => ({
  type: FETCH_DOCUMENTATIONS_REQUEST,
  payload: {
    userId,
    agentId,
    filters,
    lastItem,
    businessContext,
    retryCount,
    currentPage,
    pageSize,
  },
});

export const fetchHouseEntryInformation = ({
  agentId,
  houseId,
  businessContext,
}) => {
  const fields = ['type', 'owner', 'ciq', 'listings'];
  return {
    type: CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION,
    promise: fetchHouseEntryInformationDetails({ agentId, houseId, fields }),
    meta: {
      houseId,
      businessContext,
    },
  };
};

export const shouldFetchDocumentations = (status) => ({
  type: SHOULD_FETCH_DOCUMENTATIONS,
  payload: {
    status,
  },
});

export const documentationHouseFetchSuccess = (payload) => ({
  type: DOCUMENTATION_HOUSE_FETCH_SUCCESS,
  payload,
});

export const documentationUserFetchSuccess = (payload) => ({
  type: DOCUMENTATION_USER_FETCH_SUCCESS,
  payload,
});

export const triggerDocumentationPageViewed = (urlOriginTrack) => ({
  type: RENT_PROGRESS_STATUS_PAGE_VIEWED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_DOCUMENTATION,
    origin: urlOriginTrack,
  },
});

export const triggerDocumentationStatusCardOpened = () => ({
  type: RENT_PROGRESS_STATUS_CARD_OPENED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_DOCUMENTATION,
  },
});

export const fetchRentFlowDocumentations = ({
  startDate,
  endDate,
  sortedBy,
  currentPage,
  pageSize,
}) => ({
  type: FETCH_RENT_FLOW_DOCUMENTATIONS,
  promise: fetchRentFlowsRequest({
    step: 'DOCUMENTATION',
    startDate,
    endDate,
    sortedBy,
    currentPage,
    pageSize,
  }),
});
