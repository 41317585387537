export const AGENT_RATING = 'review/rating';
export const AGENT_UNREAD_CHAT_MESSAGES = 'chat/unread_messages';
export const CONTRACT_HOURS = 'time-control/contract-hours';
export const FETCH_AGENT_SALE_OFFERS = 'offers/agent';
export const OFFER_STATUS_LIST = 'offers/:id/status-list';
export const FINISH_FOLLOW_UP = 'scheduling/follow-up';
export const FIREBASE_AUTHENTICATE = 'firebase/authenticate';
export const FIXED_BLOCKS = 'time-control/fixed-block';
export const FIXED_TIME_UNBLOCK = 'time-control/fixed-block/unblock';
export const FOLLOW_UP_ENTRANCE_REASONS =
  'scheduling/entrance-problems/reasons';
export const FOLLOW_UP_KEY_INFO = 'follow-up/agent-keys';
export const FOLLOW_UP_MULTIPLE_HOUSES = 'follow-up/multiple-houses';
export const HOUSES = 'houses';
export const HOUSE_SUGGESTIONS = 'v1/suggestions';
export const HOUSE_SUGGESTIONS_BY_USER = 'v1/suggestions/user';
export const IS_AGENT = 'agents/accredited';
export const POOL_VISIT_CANCEL = 'visits/cancel/pool';
export const SEND_OWNER_FEEDBACK = 'visits/:visitCode/owner-feedback';
export const OFFER_SEND_PROPOSAL_LINK = 'offer/send-link-new-offer';
export const REGISTER_AVAILABILITY = 'scheduling/register/times';
export const SCHEDULE_STATUS_CHANGE = 'scheduling/:id/status-changes/:status';
export const SCHEDULING_AVAILABILITY = 'scheduling/availability';
export const SCHEDULING_AVAILABILITY_V3 =
  'v3/visit/house/:houseId/agent/scheduleAvailability';
export const SUPPORT_REGISTER = 'support/register';
export const SUPPORT_UPDATE = 'support/:supportId';
export const SUPPORT_LATEST = 'support/latest';
export const TEMPORARY_BLOCKS = 'time-control/temporary-block';
export const TEMPORARY_BLOCKS_HOURS_RANGE =
  'time-control/temporary-block/hours-range';
export const USERS = 'users';
export const VISIT_CANCEL = 'visits/cancel';
export const VISIT_CANCEL_REASONS = 'visits/cancel/reasons';
export const VISIT_CANCEL_REASONS_KEYS = 'v3/visits/cancel/reasons';
export const VISIT_GET_CANCEL_REASONS = 'v4/visits/cancel/reasons';
export const VISITS_TO_FINISH = 'visits/pending';
export const VISITS_SCHEDULES = 'visits/schedules';
export const HOUSE_INFO = 'houses/:houseId/v2';
export const VISITOR_INFO = 'visitor/:id/info';
export const NEXT_VISITS_WITH_ME = 'visitor/:id/next-visits-with-me';
export const VISITOR_BUYER_PROFILE = 'visitor/:id/buyer-profile';
export const HOUSES_V2 = 'v2/houses';
export const HOUSES_USERS_INFO = 'v2/houses/info-with-users';
export const CITY_PARAMETERS = 'city/parameters';
export const HOUSE_TENANT = 'v2/houses/:houseId/tenant';
export const HOUSE_CHARACTERISTICS = 'v2/houses/:houseId/characteristics';
export const AGENT_LISTING_REPORT = 'houses/agent-listing-report';
export const SALE_HOUSES_PERFORMANCE = 'houses/:id/sale-offers/summary';
export const VISITOR_LAST_VISITS = 'visitor/:id/last-visits';
export const RETURN_KEY = 'house-agents/return-key';
export const GET_KEY = 'house-agents/get-key';
export const REGION_SEARCH = 'region/search';
export const FETCH_KEY_TRACKING_BY_HOUSE_ID = 'house-agents/key-tracking';
export const CLIENTS = 'clients';
export const CLIENTS_V2 = 'v2/clients';
export const KEY_TRACKING = 'house-agents/agent-key-tracking';
export const SHOW_AGENT_KEYS = 'house-agents/show-area';
export const ALL_CLIENTS = 'agents/all-clients';
export const CLIENTS_ATTENDANCE = 'agents/clients/attendance';
export const CLIENTS_PRIORITIZATION = 'agents/clients/prioritization';
export const FETCH_ATTENDANCE_BY_CLIENT_ID = 'clients/:clientId/attendance';
export const FETCH_ONGOING_SALES_FLOWS_BY_CLIENT_ID =
  'clients/:clientId/ongoing-sale-offers';
export const CREATE_CLIENT_NOTES = 'clients/:clientId/notes';
export const FETCH_VISITOR_FAVORITE_HOUSES =
  'visitor/:visitorId/favorite-houses';
export const SIMILAR_HOUSES = 'v2/similar-houses';
export const HOUSE_SUGGESTIONS_V2 = 'v2/house-suggestions';
export const CLIENT_NOTES = 'clients/:clientId/notes';
export const DEMAND_CONTACT_SUBMISSIONS =
  'demand-contact-submission/contact-submissions';
export const VISIT_CHECKIN_STATUS = 'visit-checkin/:visitCode/status';
export const AGENT_VISIT_CHECKINS = 'visit-checkin';
export const VISIT_CHECKIN_CONFIRM = 'visit-checkin/:visitCode/confirm';
export const VISIT_CHECKIN_FAIL = 'visit-checkin/:visitCode/fail';
export const AGENT_PERFORMANCE = 'agents/performance';
export const SEND_AGENT_LEAD_REFERRAL = 'agents/lead-referral';
export const AGENT_LEAD_REFERRAL = 'agents/lead-referral';
export const ENABLE_AGENT_SERVICE_LINK = 'agents/service-link/enable';
export const AGENT_VISIT_LIST_V3 = 'v3/visit/agent/list';
export const AGENT_CONFIRM_VISIT_BY_CODE_V3 =
  'v3/visit/agent/:visitCode/confirm';
export const AGENT_VISIT_BY_CODE_V3 = 'v3/visit/agent/singleVisit/:visitCode';
export const AGENT_CREATE_VISIT_V3 = 'v3/visit/agent/request';
export const AGENT_REGISTER_VISIT_V3 = 'v3/visit/agent/register';
export const AGENT_UPDATE_VISIT_V3 = 'v3/visit/agent/request/:visitCode';
export const FOLLOW_UP_V3 = 'v3/visit/agent/:visitCode/follow-up';
export const AGENT_VALIDATE_VA_FEATURE_ROLLOUT =
  'AGENT_VALIDATE_VA_FEATURE_ROLLOUT';
export const AGENT_VISIT_CONFIGURATION = 'v3/visit/agent/:userId/configuration';
export const UPDATE_HOUSE_KEY_LOCATION =
  'house/:houseId/key-location/:journey/:performerRole';
export const GET_HOUSE_KEY_LOCATION =
  'house/:houseId/key-location/:journey/:performerRole';
export const GET_HOUSE_ENTRY_ACCESS_LIST = 'house/:houseId/entry-access/list';
export const UPDATE_HOUSE_ENTRY_ACCESS = 'house/:houseId/entry-access';
export const VISIT_AGENT_CHAT_V3 = 'v3/visit/agent/chat';
export const VISIT_DETAIL_ENDPOINT = '/page/visit-detail';
export const VISITOR_NEGOTIATION_QUALIFICATION =
  'v3/visit/visitor/:userId/qualification';
export const HOUSE_VISIT_CONFIGURATION =
  'v3/visit/house/:houseId/configuration';
export const SEND_DATA_SUBJECT_PRIVACY_HUB = 'privacy-hub/send-data-subject';
