export const FIREBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: `${process.env.FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: `${process.env.FIREBASE_MESSAGING_SENDER_ID}`,
};

export const FIREBASE_AUTH_STATUS = Object.freeze({
  NOT_VERIFIED: 'NOT_VERIFIED',
  LOGGED: 'LOGGED',
  LOGGED_ANONYMOUSLY: 'LOGGED_ANONYMOUSLY',
  NOT_LOGGED: 'NOT_LOGGED',
});
