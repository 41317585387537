export const OWNER_CONTACT_FOR_RENT_FEATURE_FLAG =
  'enable_owner_contact_for_rent';

export const OWNER_CONTACT_TRACKING_EVENTS = Object.freeze({
  TERMS_CONDITIONS_PAGE_VIEWED: 'owner_contact_terms_conditions_page_viewed',
  REASON_PAGE_VIEWED: 'owner_contact_reason_page_viewed',
  SUCCESS_PAGE_VIEWED: 'owner_contact_success_page_viewed',
  REQUEST_CLICKED: 'owner_contact_request_clicked',
  CLOSE_CLICKED: 'owner_contact_close_clicked',
  OPEN_CLICKED: 'owner_contact_open_clicked',
  ACCEPT_CONTINUE_CLICKED: 'owner_contact_accept_continue_clicked',
  REASON_OPTION_CLICKED: 'owner_contact_reason_option_clicked',
  SEND_MESSAGE_CLICKED: 'owner_contact_send_message_clicked',
  PHONE_CALL_CLICKED: 'owner_contact_phone_call_clicked',
});
