import { fetchHouseEntryInformationDetails } from 'api/agents/house';
import { CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION } from '../Documentations/constants';
import {
  RENT_PROGRESS_STATUS_PAGE_VIEWED,
  RENT_PROGRESS_STATUS_NEGOTIATION,
  RENT_PROGRESS_STATUS_CARD_OPENED,
} from './../constants';

import {
  FETCH_OFFERS_REQUEST,
  OFFER_HOUSE_FETCH_SUCCESS,
  OFFER_USER_FETCH_SUCCESS,
  SHOULD_FETCH_OFFERS,
  FETCH_RENT_FLOW_OFFERS,
} from './constants';
import { fetchRentFlowsRequest } from '../Data/fetchRentFlowsRequest';

export const fetchOffersRequest = ({
  agentId,
  filters,
  lastItem,
  businessContext,
  currentPage,
  pageSize,
  retryCount,
}) => ({
  type: FETCH_OFFERS_REQUEST,
  payload: {
    agentId,
    filters,
    lastItem,
    businessContext,
    currentPage,
    pageSize,
    retryCount,
  },
});

export const shouldFetchOffers = (status) => ({
  type: SHOULD_FETCH_OFFERS,
  payload: {
    status,
  },
});

export const fetchHouseEntryInformation = ({
  agentId,
  houseId,
  businessContext,
}) => {
  const fields = ['type', 'owner', 'ciq', 'listings'];
  return {
    type: CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION,
    promise: fetchHouseEntryInformationDetails({ agentId, houseId, fields }),
    meta: {
      houseId,
      businessContext,
    },
  };
};

export const offerHouseFetchSuccess = (payload) => ({
  type: OFFER_HOUSE_FETCH_SUCCESS,
  payload,
});

export const offerUserFetchSuccess = (payload) => ({
  type: OFFER_USER_FETCH_SUCCESS,
  payload,
});

export const triggerNegotiationPageViewed = (urlOriginTrack) => ({
  type: RENT_PROGRESS_STATUS_PAGE_VIEWED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_NEGOTIATION,
    origin: urlOriginTrack,
  },
});

export const triggerNegotiationStatusCardOpened = () => ({
  type: RENT_PROGRESS_STATUS_CARD_OPENED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_NEGOTIATION,
  },
});

export const fetchRentFlowOffers = ({
  startDate,
  endDate,
  sortedBy,
  currentPage,
  pageSize,
}) => ({
  type: FETCH_RENT_FLOW_OFFERS,
  promise: fetchRentFlowsRequest({
    step: 'OFFER',
    startDate,
    endDate,
    sortedBy,
    currentPage,
    pageSize,
  }),
});
