import { Iterable } from 'immutable';
import { createSelector } from 'reselect';

import { makeSelectUser } from '@quintoandar/bioma-auth/containers/selectors';

import {
  makeSelectFixedBlockByDate,
  makeSelectTemporaryBlockByDate,
} from 'containers/TimeBlock/selectors';
import { formatDateYearMonthDayPattern } from 'utils/date';
import { formatFirstName } from 'utils/string';
import { immutableToImmer } from 'utils';

import { filterVisit, filterDeletedVisits, buildScheduleGroups } from './utils';

const getVisitorsActionsIfValid = (visitorsActions) => {
  if (!visitorsActions) {
    return [];
  }

  if (Iterable.isIterable(visitorsActions)) {
    return Object.values(visitorsActions.toJS());
  }

  return [];
};

export const selectAgenda = (state) => state.get('agenda');
export const selectVisitorsActions = (state) =>
  selectAgenda(state).get('visitorsActions');
export const selectInterfaceOptionsData = (state) =>
  selectAgenda(state).get('userInterfaceOptions');
export const selectCancelDialog = (state) =>
  selectInterfaceOptionsData(state).get('cancelDialog');
export const selectFollowUp = (state) =>
  selectInterfaceOptionsData(state).get('followUp');
export const selectNewVisitDialog = (state) =>
  selectInterfaceOptionsData(state).get('newVisitDialog');
export const selectVisitCheckins = (state) =>
  selectAgenda(state).get('visitCheckins');
export const selectRescheduleVisitDialog = (state) =>
  selectInterfaceOptionsData(state).get('rescheduleVisitDialog');
export const selectVisitConfirmationDialog = (state) =>
  selectInterfaceOptionsData(state).get('visitConfirmationDialog');
export const selectFinishVisitDialog = (state) =>
  selectInterfaceOptionsData(state).get('finishVisit');
export const selectCurrentTabIndex = (state) =>
  selectInterfaceOptionsData(state).get('currentTabIndex');
export const selectPoolActionsDialog = (state) =>
  selectInterfaceOptionsData(state).get('poolActionsDialog');
export const selectVisits = (state) => selectAgenda(state).get('visits');
export const selectFeatureFlags = (state) =>
  selectAgenda(state).get('featureFlags');

export const makeSelectVisitorActions = () =>
  createSelector(selectVisitorsActions, (visitorsActions) =>
    getVisitorsActionsIfValid(visitorsActions),
  );

export const makeSelectCancelReasonDialogIsOpen = () =>
  createSelector(selectCancelDialog, (cancelDialog) =>
    cancelDialog.get('isOpen'),
  );

export const makeSelectPoolActionsDialogIsOpen = () =>
  createSelector(selectPoolActionsDialog, (poolActionsDialog) =>
    poolActionsDialog.get('isOpen'),
  );

export const makeSelectFollowUpDialogIsOpen = () =>
  createSelector(selectFollowUp, (follow) => follow.get('isOpen'));

export const makeSelectNewVisitDialogIsOpen = () =>
  createSelector(selectNewVisitDialog, (newVisitDialog) =>
    newVisitDialog.get('isOpen'),
  );

export const makeSelectVisitCheckins = () =>
  createSelector(selectVisitCheckins, (visitCheckins) => visitCheckins);

export const makeSelectRescheduleVisitDialogIsOpen = () =>
  createSelector(selectRescheduleVisitDialog, (rescheduleVisit) =>
    rescheduleVisit.get('isOpen'),
  );

export const makeSelectVisitConfirmationDialogIsOpen = () =>
  createSelector(selectVisitConfirmationDialog, (visitConfirmationDialog) =>
    visitConfirmationDialog.get('isOpen'),
  );

export const makeSelectVisitConfirmationVisitCode = () =>
  createSelector(selectVisitConfirmationDialog, (visitConfirmationDialog) =>
    visitConfirmationDialog.get('visitCode'),
  );

export const makeSelectCancelReasonDialogSelectedVisitor = () =>
  createSelector(selectCancelDialog, (cancelDialog) =>
    cancelDialog.get('selectedVisitor').toJS(),
  );

export const makeSelectCancelReasonDialogHouse = () =>
  createSelector(selectCancelDialog, (cancelDialog) =>
    cancelDialog.get('house').toJS(),
  );

export const makeSelectCancelReasons = () =>
  createSelector(selectCancelDialog, (state) => state.get('reasons').toJS());
export const makeSelectHasCancelReasons = () =>
  createSelector(selectCancelDialog, (state) =>
    Boolean(state.get('reasons').size),
  );

export const makeSelectAgentName = createSelector(
  immutableToImmer(makeSelectUser()),
  (state) => formatFirstName(state.name),
);

export const makeSelectAgentFullName = () =>
  createSelector(immutableToImmer(makeSelectUser()), (state) => state.name);

export const makeSelectUserId = () =>
  createSelector(immutableToImmer(makeSelectUser()), (state) => state.id);

export const makeSelectAgentId = () =>
  createSelector(immutableToImmer(makeSelectUser()), (state) => state.agentId);

export const makeSelectVisitsUpdatedAt = () =>
  createSelector(selectInterfaceOptionsData, (state) =>
    state.get('visitsUpdatedAt'),
  );

export const makeSelectCurrentDayVisits = (date) =>
  createSelector(selectVisits, (visits) => visits.get(date));

export const makeSelectDateVisits = (date) => {
  const formattedDate = formatDateYearMonthDayPattern(date);
  return createSelector(
    makeSelectCurrentDayVisits(formattedDate),
    (currentDayVisits) => {
      if (!currentDayVisits) {
        return currentDayVisits;
      }
      const filteredVisits = currentDayVisits
        .filter(filterDeletedVisits)
        .map(filterVisit);
      return filteredVisits.toJS();
    },
  );
};

export const makeSelectScheduleGroupsByDate = (date) =>
  createSelector(
    makeSelectDateVisits(date),
    makeSelectTemporaryBlockByDate(date),
    makeSelectFixedBlockByDate(date),
    (visits, temporaryBlocks, fixedBlocks) =>
      buildScheduleGroups(visits, temporaryBlocks, fixedBlocks),
  );

export const makeSelectVisit = (date, visitUniqueId) =>
  createSelector(selectVisits, (visits) => {
    const visit = visits.getIn([date, visitUniqueId]);
    const filteredVisit = filterVisit(visit);
    return filteredVisit.toJS();
  });

export const makeSelectVisits = () =>
  createSelector(selectVisits, (visits) => visits.toJS());

export const makeSelectPoolActionsData = () =>
  createSelector(selectPoolActionsDialog, (dialog) =>
    dialog.get('data').toJS(),
  );

export const makeSelectIsFinishVisitOpen = () =>
  createSelector(selectFinishVisitDialog, (dialog) => dialog.get('isOpen'));

export const makeSelectIsFromClientTab = () =>
  createSelector(selectInterfaceOptionsData, (userInterfaceOptions) =>
    userInterfaceOptions.get('isFromClientTab'),
  );

export const makeSelectIsVisitsLoading = () =>
  createSelector(selectAgenda, (state) => state.get('isVisitsLoading'));

export const makeSelectHasVisitsError = () =>
  createSelector(selectAgenda, (state) => state.get('hasVisitsError'));

export const makeSelectFeatureFlags = () =>
  createSelector(selectFeatureFlags, (featureFlags) => featureFlags.toJS());
